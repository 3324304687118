$(function(){
	var state = false;
	var scrollpos;
	$('.popup-modal').magnificPopup({
		midClick: true,
		mainClass: 'mfp-fade mfp-type',
		removalDelay: 150,
		showCloseBtn: false,
		callbacks: {
			open: function() {
				if(state == false) {
					scrollpos = $(window).scrollTop(); 
					$('body').addClass('fixed').css({'top': -scrollpos});
					state = true;
				}
			},
			close: function(){
				$('body').removeClass('fixed').css({'top': 0});
				window.scrollTo( 0 , scrollpos ); 
				$('body').removeClass('fixed');
				state = false;
			}
		}
	});
	$('.popup-modal-close').click(function (e) {
		e.preventDefault();
		$.magnificPopup.close();
		return false;
	});
})
$(window).on('load',function(){
	initialize('map',34.27958897192309, 135.30060464025843);
});
var zoom = 15;
if(window.innerWidth < 896){
	zoom = 14;
}
function initialize(id,n,m) {
	var latlng = new google.maps.LatLng(n,m);
	var myOptions = {
		zoom: zoom,
		center: latlng,
		scrollwheel: false,
		mapTypeControl: false,
		panControl: false,
		streetViewControl: false,
		zoomControl: true,
		mapTypeId: google.maps.MapTypeId.ROADMAP
	};
	var map = new google.maps.Map(document.getElementById(id), myOptions);
	
	//アイコン設定
	var icon = {
			url : "https://cdn.shopify.com/s/files/1/0559/8674/9603/files/map_icon_0ac91253-297f-47d0-bf17-6dffbe4b34f3.png",
			scaledSize : new google.maps.Size(80, 94),
	}
	if(window.innerWidth < 896){
		icon = {
			url : "https://cdn.shopify.com/s/files/1/0559/8674/9603/files/map_icon_0ac91253-297f-47d0-bf17-6dffbe4b34f3.png",
			scaledSize : new google.maps.Size(54, 63),
		}
	}

	var markerOptions = {
		position: latlng,
		map: map,
		icon: icon,
		title: '',
	};
	var marker = new google.maps.Marker(markerOptions);
	
	google.maps.event.addDomListener(window, "resize",
			function() {
				map.setCenter(latlng)
			})
	
	var styleOptions = [];
					
	var lopanType = new google.maps.StyledMapType(styleOptions);
	map.mapTypes.set('maptype', lopanType);
	map.setMapTypeId('maptype');
}